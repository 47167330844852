import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import App from './App';
import Header from './components/componentes/header';
import Footer from './footer';


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter> {/* Wrap the App in BrowserRouter */}
<Header/>
      <App />
      <Footer/>
    </BrowserRouter>
  </React.StrictMode>
);
