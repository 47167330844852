import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FiPhone, FiMail } from 'react-icons/fi';
import logo from "../globus.jpeg"


const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-left">
        <div className="logo-container">
          <img
            src={logo}
            alt="Company Logo"
            className="logo"
          />
        </div>
      </div>
      <div className="header-right">
        <div className="header-top">
          <div className="contact-info">
            <span className="contact-item">
              <FiPhone style={{ marginRight: '8px' }} /> +1 786 474 2836
            </span>
            <span className="contact-item" style={{ marginLeft: '20px' }}>
              <FiMail style={{ marginRight: '8px' }} /> info@globuscargo.com
            </span>
          </div>
        </div>
        <div className="header-bottom">
          <div className="hamburger-menu" onClick={toggleMenu}>
            <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
          </div>
          <nav className={`nav-menu ${menuOpen ? 'show' : ''}`}>
            
            <a href="https://globuscargo.us/#/sign-in" className="nav-link">Regístrese</a>
            <a href="#casillero" className="nav-link">Casillero</a>
            <a href="https://globuscargo.com/rastrea/" className="nav-link">Rastreo</a>
            <a href="https://www.zonapagos.com/pagosn2/LoginCliente" className="nav-link">Zona de Pagos</a>
            <a href="https://cotizador.globuscargo.com/?_gl=1*gdk05o*_ga*NTI5NjQ1NDAuMTcwMDUyODM3Mw..*_ga_75NN7YXPET*MTczMDMxNzI4MS41NS4xLjE3MzAzMTczMjMuMC4wLjA." className="nav-link">Cotizar</a>
            <a href="https://globuscargo.com/contact-us/" className="nav-link">Contáctenos</a>
            <a href="https://agencias.globuscargo.us/#/sign-in" className="nav-link">Ingreso agencia</a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
