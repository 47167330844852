import React from 'react';
import { Link } from 'react-router-dom';
import logo from "./imagenes/gib logo.jpeg"


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <img
            src="https://globuscargo.com/wp-content/uploads/2020/08/LOGO-GLOBUS_CARGO-e1597780560604.png" // Add your logo path here
            alt="Globus Cargo Logo"
            className="footer-logo"
          />
          <p>
            Globus Cargo, soluciones logísticas a la medida de sus necesidades. 
            <br />
            "tu conexión al mundo"
          </p>
        </div>

        <div className="footer-options">
          <h3>Opciones</h3>
          <ul>
  <li><a href="https://globuscargo.us/#/sign-in" className="nav-link">Regístrese</a></li>
  <li><a href="#casillero" className="nav-link">Casillero</a></li>
  <li><a href="https://globuscargo.com/rastrea/" className="nav-link">Rastreo</a></li>
  <li><a href="https://www.zonapagos.com/pagosn2/LoginCliente" className="nav-link">Zona de Pagos</a></li>
  <li><a href="https://cotizador.globuscargo.com/?_gl=1*gdk05o*_ga*NTI5NjQ1NDAuMTcwMDUyODM3Mw..*_ga_75NN7YXPET*MTczMDMxNzI4MS41NS4xLjE3MzAzMTczMjMuMC4wLjA." className="nav-link">Cotizar</a></li>
  <li><a href="https://globuscargo.com/contact-us/" className="nav-link">Contáctenos</a></li>
  <li><a href="#agencia" className="nav-link">Ingreso agencia</a></li>
</ul>


        </div>

        <div className="footer-links">
          <h3>Accesos Directos</h3>
          
          <ul>
     
  <li><a href="https://cotizador.globuscargo.com/?_gl=1*gdk05o*_ga*NTI5NjQ1NDAuMTcwMDUyODM3Mw..*_ga_75NN7YXPET*MTczMDMxNzI4MS41NS4xLjE3MzAzMTczMjMuMC4wLjA." className="nav-link">Cotizar</a></li>
  <li><a href="https://globuscargo.com/terminos-y-condiciones/" className="nav-link">Política de tratamiento de datos</a></li>
  <li><a href="https://nuevaversion.globusbox.com/preguntas-frecuentes/" className="nav-link">Preguntas frecuentes</a></li>

</ul>
        
        </div>

        <div className="footer-contact">
          <h3>Contacto</h3>
          <p>2120 NW 96 AVENUE, Miami FL, 33172</p>
          <p>+1 786 474 2836</p>
          <p><a href="mailto:info@globuscargo.com">info@globuscargo.com</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright 2023 GlobusCargo todos los derechos reservados.</p>
     
      </div>
    </footer>
  );
};

export default Footer;
